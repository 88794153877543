import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storage } from '@capacitor/storage';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  language;

  private eventCountDown = new BehaviorSubject(-1)

  constructor(
    private http: HttpClient,
  ) {

    // this.language = this.languageService.language();

  }

  getMasterData(){
    return this.http.get<any>(
      `${environment.host}/master`
    );
  }

  getEventList(){
    return this.http.get<any>(
      `${environment.host}/events?limit=20`
    );
  }

  getEvenCountdown(){
    return this.http.get<any>(
      `${environment.host}/events/${environment.parentEvent}/countdown`
    );
  }

  setEventCountdown(value){

    this.eventCountDown.next(value)

  }

  getEventCountdownObserver(){

    return this.eventCountDown

  }

}
