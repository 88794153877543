import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'joint-event',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forget',
    loadChildren: () => import('./forget/forget.module').then( m => m.ForgetPageModule)
  },
  {
    path: 'forget-otp',
    loadChildren: () => import('./forget-otp/forget-otp.module').then( m => m.ForgetOtpPageModule)
  },
  {
    path: 'forget-setpass',
    loadChildren: () => import('./forget-setpass/forget-setpass.module').then( m => m.ForgetSetpassPageModule)
  },
  {
    path: 'forget-success',
    loadChildren: () => import('./forget-success/forget-success.module').then( m => m.ForgetSuccessPageModule)
  },
  {
    path: 'register/:eventID',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'register-success',
    loadChildren: () => import('./register-success/register-success.module').then( m => m.RegisterSuccessPageModule)
  },
  {
    path: 'event-home',
    loadChildren: () => import('./event-home/event-home.module').then( m => m.EventHomePageModule)
  },
  {
    path: 'event-home/home',
    loadChildren: () => import('./profile/my-event/my-event.module').then( m => m.MyEventPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'popup-join',
    loadChildren: () => import('./popup-join/popup-join.module').then( m => m.PopupJoinPageModule)
  },
  {
    path: 'popup-option',
    loadChildren: () => import('./popup-option/popup-option.module').then( m => m.PopupOptionPageModule)
  },
  {
    path: 'popup-thanks',
    loadChildren: () => import('./popup-thanks/popup-thanks.module').then( m => m.PopupThanksPageModule)
  },
  {
    path: 'crop-image',
    loadChildren: () => import('./crop-image/crop-image.module').then( m => m.CropImagePageModule)
  },
  {
    path: 'popup-yesno',
    loadChildren: () => import('./popup-yesno/popup-yesno.module').then( m => m.PopupYesnoPageModule)
  },
  {
    path: 'popup-errorform',
    loadChildren: () => import('./popup-errorform/popup-errorform.module').then( m => m.PopupErrorformPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
