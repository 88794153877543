import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storage } from '@capacitor/storage';
import { tap, map, take, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from } from 'rxjs';
import { Token } from '../models/token.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  language;

  private _user = new BehaviorSubject<User>(null);

  constructor(
    private http: HttpClient,
  ) {

    // this.language = this.languageService.language();

  }

  get userIsAuthenticated() {

    return from(Storage.get({key: 'tokenData'})).pipe(
      map(tokenData => {
        if(!tokenData || !tokenData.value){
          return false;
        }else{
          let token = new Token().deserialize(JSON.parse(tokenData.value))

          return !token.isTokenExpired()

        }
      })
    );

  }

  get token() {

    return from(Storage.get({key: 'tokenData'})).pipe(
      map(tokenData => {
        if(!tokenData || !tokenData.value){
          return null;
        }

        let token = new Token().deserialize(JSON.parse(tokenData.value))

        return token.getAccessToken();
      })
    );

    // return this._token.asObservable().pipe(
    //   map(token => {
    //     if(token) {
    //       return token.access_token;
    //     } else {
    //       return null;
    //     }
    //   })
    // );
  }

  get user(){
    // var dataUser =  this._user.value;
    // if(dataUser){
    //   localStorage.dataGlobal =  JSON.stringify(dataUser);
    // }
    // return this._user.asObservable();

    return from(Storage.get({key: 'authData'})).pipe(
      map(authData => {
        if(!authData || !authData.value){
          return null;
        }

        return JSON.parse(authData.value);
      })
    );

  }

  registerMember(form){

    let body = new FormData();

    if(form.eventID){
      body.append('eventID',form.eventID);
    }

    if(form.invitationType){
      body.append('invitationType',form.invitationType);
    }

    if(form.prefix){
      body.append('prefix',form.prefix);
    }

    if(form.name){
      body.append('name',form.name);
    }

    if(form.last_name){
      body.append('last_name',form.last_name);
    }

    if(form.kind_of_organization){
      body.append('kind_of_organization',form.kind_of_organization);
    }

    if(form.organization){
      body.append('organization',form.organization);
    }

    if(form.country){
      body.append('country',form.country);
    }

    if(form.codephone){
      body.append('codephone',form.codephone);
    }

    if(form.phone){
      body.append('phone',form.phone);
    }

    if(form.email){
      body.append('email',form.email);
    }

    if(form.password){
      body.append('password',form.password);
    }

    if(form.password_confirmation){
      body.append('password_confirmation',form.password_confirmation);
    }

    if(form.filePhoto){
      body.append('filePhoto',form.filePhoto);
    }

    if(form.filePasport){
      body.append('filePasport',form.filePasport);
    }

    if(form.pasportType){
      body.append('pasportType',form.pasportType);
    }

    if(form.passportNumber){
      body.append('passportNumber',form.passportNumber);
    }

    if(form.passportExpiredDate){
      body.append('passportExpiredDate',form.passportExpiredDate);
    }

    if(form.passportIssuingPlace){
      body.append('passportIssuingPlace',form.passportIssuingPlace);
    }

    if(form.participantType){
      body.append('participant_type',form.participantType);
    }

    if(form.agreement){
      body.append('agreement',form.agreement);
    }

    if(form.token){
      body.append('token',form.token);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        // 'X-Agent': 'android'
        'X-Localization': 'id'
      })
    };

    return this.http.post<any>(
      `${environment.host}/auth/register`,
      body,
      httpOptions
    );

  }

  loginUsername(email, password){
    let body = new FormData();
    body.append('email',email);
    body.append('password', password)
    body.append('token', 'bypass');

    // if(isForce){
    //   body.append('force', "1");
    // }

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-Agent': 'android',
        'X-Localization': 'id'
      })
    };

    return this.http.post<any>(
      `${environment.host}/auth/login`,
      body, httpOptions
    )
    .pipe(
      map(resData => {
        return resData;
      }),
      // tap(this.setUserData.bind(this))
    );
  }

  forgotPassword(email){
    const deep_link = environment.forgotPassUrl;

    let body = {};

    body = {
      "email": email,
      "deep_link": deep_link,
      "token": 'bypass'
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-Agent': 'android',
        'X-Localization': 'id'
      })
    };

    return this.http.post<any>(
      `${environment.host}/auth/forgot`,
      body,
      httpOptions
    );
  }

  resetPassword(params: any, token) {

    let body = {};

    body = params

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-Agent': 'android',
        'X-Localization': 'id'
      })
    };

    return this.http.put<any>(
      `${environment.host}/auth/forgot/${token}`,
      body,
      httpOptions
    );

  }

  setUserToken(data: any) {

    const authData = JSON.stringify({
      access_token: data.access_token,
      token_type: data.token_type,
      expires_in: data.expires_in,
      setDate: Math.floor(Date.now() / 1000)
    });

    Storage.set({key: 'tokenData', value: authData});

  }

  setUserData(data: any) {
    const user = new User().deserialize(data);

    this._user.next(user);

    Storage.set({key: 'authData', value: JSON.stringify(data)});

    Storage.get({ key: 'tokenData' }).then((val) => {
      // this.callForceRedirectFunction(val, user);
    });

  }

  setUserObserver(value){

    this._user.next(value)

  }

  getUserObserver(){

    return this._user

  }

}
