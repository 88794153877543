import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { User } from './models/user.model';
import { AuthService } from './service/auth.service';
import { EventService } from './service/event.service';
import { MemberService } from './service/member.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  user:User = new User().deserialize('')

  intervalEventCountdown:any

  constructor(
    private eventService: EventService,
    private authService: AuthService,
    private router: Router,
    private memberService: MemberService
  ) {

    this.getMasterData()
    this.getEventCountdown()

    this.authService.userIsAuthenticated.subscribe(
      data => {
        if(data){

          this.authService.user.subscribe(user => {

            this.user = new User().deserialize(user) ;

            this.authService.setUserData(user)

            this.getProfile()

          });

        }
      }
    )

  }

  getMasterData(){

    this.eventService.getMasterData().subscribe(
      res => {

        Storage.set({key:'materData', value:JSON.stringify(res.data)})

      }, err => {

      }
    )

  }

  getEventCountdown(){

    this.eventService.getEvenCountdown().subscribe(
      res => {



      },
      err => {

      }
    )

  }

  setTimerEventCountdown(seconds) {
    let ionThis = this;

    if(this.intervalEventCountdown){
      clearInterval(this.intervalEventCountdown)
    }

    this.intervalEventCountdown = setInterval(function () {
      seconds = seconds - 1;
      ionThis.eventService.setEventCountdown(seconds)

      if (seconds <= 0 ) {

        // ionThis.getOngoingSession()
        clearInterval(ionThis.intervalEventCountdown);

      }

      // console.log(seconds);
    }, 1000)
  }

  getProfile() {

    this.memberService.getMemberProfile().subscribe(res => {

      if (res.data) {

        this.authService.setUserData(res.data);
        // this.router.navigateByUrl("/event-home", { replaceUrl: true });

      }


    });

  }

}
