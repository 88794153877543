import { Deserializable } from "./deserializable.model"

export interface MyEvent {
	event_id: number,
	event_name: string,
	event_image: string,
	event_location: string,
	event_start_date: string,
	event_end_date: string
}

export class User implements Deserializable {

	public id: string
  private prefix:string
	public name: string
  public last_name: string
	public email: string
	public phone: string
  public kind_of_organization:any
  public organization:string
  private filePhoto:string
  private filePasport:string
  private country:string
  public codephone:string
  private pasportType: string
  private passportNumber: string
  private passportExpiredDate: string
  private passportIssuingPlace: string
  private svg:string
	public participant_type:string

	public constructor(

	) {}

	deserialize(input: any) {
		Object.assign(this, input);

		return this;
	}

	getID(){
		return this.id
	}

  getPrefix(){
    return this.prefix
  }

	getName(){
		return this.name
	}

  getLastName(){
    return this.last_name
  }

	getInitial(){

		if(this.name){

      let fullnname = this.name+" "+this.last_name

			let member_name = fullnname.split(" ");
			if (member_name.length > 1) {

				return member_name[0].charAt(0).toUpperCase() + member_name[1].charAt(0).toUpperCase();

			} else {

				return member_name[0].charAt(0).toUpperCase();

			}
		}

	}

	getEmail(){
		return this.email
	}

	getPhone(){
		return this.phone
	}

	getPhoneCode(){
		return this.codephone
	}

  getKindOfOrganization(){
    return this.kind_of_organization
  }

  getOrganization(){
    return this.organization
  }

  getFilePhoto(){
    return this.filePhoto
  }

  getFilePaspport(){
    return this.filePasport
  }

  getCountry(){
    return this.country
  }

  getPasportType(){
    if(this.pasportType){
      return this.pasportType
    }else{
      return ''
    }

  }

  getPassportNumber(){

    if(this.passportNumber){
      return this.passportNumber
    }else{
      return ''
    }

  }

  getPassportExpiredDate(){
    return this.passportExpiredDate
  }

  getPassportIssuingPlace(){
    if(this.passportIssuingPlace){

      return this.passportIssuingPlace

    }else{

      return ''

    }

  }

  getQR(){
    return this.svg
  }

	getParticipantType(){
		return this.participant_type
	}

}


export class Connections {
	public constructor(
		public id: string,
		public name: string,
		public email: string,
		public phone: string,
		public company_name: string,
		public company_position: string,
		public photo_url: string,
		public interests: string
	) {}
}
