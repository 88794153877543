import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Plugins } from "@capacitor/core";
import { ModalController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
providedIn: 'root'
})
export class MemberService {

    language='id';

    private myEvent = new BehaviorSubject<any>(null)
    private updateMyEvent = new BehaviorSubject<boolean>(false)

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private router: Router,
        private modalController: ModalController
    ) {

        this.language = 'id'

    }

    getMemberProfile(){

        return this.authService.token.pipe(
          take(1),
          switchMap(token => {

            // console.log(token)

            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
              })
            };

            return this.http.get<any>(
              `${environment.host}/users`,
              httpOptions
            );
          })
        );

    }

    editProfile(params, participant_type) {
        return this.authService.token.pipe(
          switchMap(token => {


            let phone = ''

            let body = new FormData();
            body.append('name',params.name);
            body.append('email',params.email);

            if(params.last_name){

              body.append('last_name',params.last_name);

            }

            if(params.phone){

              body.append('phone',params.phone);

            }

            if(params.participant_type){
              body.append('participant_type',params.participant_type);
            }
            // body.append('participant_type', participant_type)


            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
              })
            };
            return this.http.post<any>(
              `${environment.host}/users`,
              body,
              httpOptions
            );
          }),
          take(1)
        );
      }


    getMyEvent(){

        return this.authService.token.pipe(
          take(1),
          switchMap(token => {

            // console.log(token)

            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
              })
            };

            return this.http.get<any>(
              `${environment.host}/events/myevent`,
              httpOptions
            );
          })
        );

    }

  getMySession(){

      return this.authService.token.pipe(
        take(1),
        switchMap(token => {

          // console.log(token)

          const httpOptions = {
            headers: new HttpHeaders({
              'Authorization': `Bearer ${token}`
            })
          };

          return this.http.get<any>(
            `${environment.host}/events/mysessions`,
            httpOptions
          );
        })
      );

  }

  getMyDocument(){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        // console.log(token)

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/mydocument`,
          httpOptions
        );
      })
    );

}

  registerEvent(type, eventID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        let body = new FormData();
        body.append('invitationType',type);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.host}/events/${eventID}/register`,body,
          httpOptions
        );
      })
    );

  }

  logout() {

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.delete<any>(
          `${environment.host}/auth/logout`,
          httpOptions
        )
      })
    );

    // return this.authService.token.subscribe(
    //   token => {

    //     console.log(token)

    //     const httpOptions = {
    //       headers: new HttpHeaders({
    //         'Authorization': `Bearer ${token}`
    //       })
    //     };

    //     return this.http.delete<any>(
    //       `${environment.host}/auth/logout`,
    //       httpOptions
    //     )

    //   }
    // );


  }

  updateMember(form){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        let body = new FormData();

        if(form.eventID != '' ){
          body.append('eventID',form.eventID);
        }

        if(form.invitationType != '' ){
          body.append('invitationType',form.invitationType);
        }

        if(form.prefix != ''){
          body.append('prefix',form.prefix);
        }

        if(form.name != ''){
          body.append('name',form.name);
        }

        if(form.last_name != ''){
          body.append('last_name',form.last_name);
        }

        if(form.kind_of_organization != ''){
          body.append('kind_of_organization',form.kind_of_organization);
        }

        if(form.organization != ''){
          body.append('organization',form.organization);
        }

        if(form.country != '' ){
          body.append('country',form.country);
        }

        if(form.codephone != '' ){
          body.append('codephone',form.codephone);
        }

        if(form.phone != '' ){
          body.append('phone',form.phone);
        }

        if(form.email != '' ){
          body.append('email',form.email);
        }

        if(form.filePhoto){
          body.append('filePhoto',form.filePhoto);
        }

        if(form.filePasport){
          body.append('filePasport',form.filePasport);
        }

        if(form.pasportType != '' && form.pasportType){
          body.append('pasportType',form.pasportType);
        }

        if(form.passportNumber != '' &&  form.passportNumber){
          body.append('passportNumber',form.passportNumber);
        }

        if(form.passportExpiredDate != '' && form.passportExpiredDate){
          body.append('passportExpiredDate',form.passportExpiredDate);
        }

        if(form.passportIssuingPlace != '' && form.passportIssuingPlace ){
          body.append('passportIssuingPlace',form.passportIssuingPlace);
        }

        return this.http.post<any>(
          `${environment.host}/users`,
          body,
          httpOptions
        );


      })
    );



  }

  changePassword(oldpass, newpass, confirmnewpass){

    return this.authService.token.pipe(
      switchMap(token => {

        let body = { 'oldpassword': oldpass, 'newpassword' : newpass,  'newpassword_confirmation' : confirmnewpass };

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };
        return this.http.put<any>(
          `${environment.host}/users`,
          body,
          httpOptions
        );
      }),
      take(1)
    );


  }

  setMyEvent(val){
    this.myEvent.next(val)
  }

  getMyEventValue(){

    return this.myEvent.value

  }

  setUpdateMyEvent(val){
    this.updateMyEvent.next(val)
  }

  getupdateMyEventObserver(){

    return this.updateMyEvent

  }

}
